<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        :general-data="userData"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- payee tab -->
    <b-tab>
    <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Beneficiarios</span>
      </template>

      <account-setting-payee/>
    </b-tab>
    <!--/ payee tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Cambiar contraseña</span>
      </template>

      <account-setting-password />
    </b-tab>

    <!--/ Delete account tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Eliminar cuenta</span>
      </template>

      <account-setting-delete />
    </b-tab>
    

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingDelete from './AccountSettingDelete.vue'
import AccountSettingPayee from './AccountSettingPayee.vue'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingDelete,
    AccountSettingPayee,
  },
  data() {
    return {
      options: {},
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  }
}
</script>
