<template>
  <b-card>
    <!-- form -->
    <b-form @submit.prevent="handleSubmit()">
      <b-row>
        <!-- buttons -->
        <b-col cols="12">
          <h2>Eliminar cuenta</h2>
          <p>Para eliminar tu cuenta has click en el botón de abajo y daremos de baja tu cuenta. Si deseas reestablecerla, escribenos a contacto@compitienda.com con tu nombre de usuario.</p>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mt-1 mr-1"
            type="submit"
          >
            Eliminar cuenta
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: {
              active_status: false
            },
    }
  },
  
  methods: {
    ...mapActions('users', ['editUser']),

    handleSubmit() {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Perderás el acceso a la cuenta',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText:  'seee, bórralo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.editUser({
            id: userData.id,
            user: this.user,
          }).then(() => {
              
            // Remove userData from localStorage
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
            localStorage.removeItem('userData')

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })

            this.$swal({
              title: '¡Genial!',
              text: 'Hemos desactivado tu cuenta!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    },
  },
}
</script>
